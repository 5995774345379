/* Change the primary button color */
.firebaseui-idp-button.firebaseui-idp-primary {
    background-color: #004DFF;
  }

  .mdl-button--raised.mdl-button--colored {
    background-color: #004DFF;
  }
  
  /* Change the primary button color on hover */
  .firebaseui-idp-button.firebaseui-idp-primary:hover {
    background-color: #0039cc;
  }
  
  /* Change the primary button color when focused */
  .firebaseui-idp-button.firebaseui-idp-primary:focus {
    background-color: #0039cc;
  }
  
  /* Change the primary button color when active */
  .firebaseui-idp-button.firebaseui-idp-primary:active {
    background-color: #002d99;
  }

  .firebaseui-button {
    background-color: #004DFF !important;
    color: white !important;
}
  .firebaseui-button:hover {
    background-color: #0039cc !important;
    color: white !important;
}
.firebaseui-button:focus {
    background-color: #0039cc !important;
    color: white !important;
}
.firebaseui-button:active {
    background-color: #0039cc !important;
    color: white !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    background-color: #0039cc !important;
}

.mdl-progress > .progressbar {
    background-color: #004DFF !important;
}

.mdl-progress > .bufferbar {
    background-image: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0.7),
            hsla(0, 0%, 100%, 0.7)
        ),
        linear-gradient(90deg, #004DFF, #004DFF) !important;
    z-index: 0;
    left: 0;
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0.9),
            hsla(0, 0%, 100%, 0.9)
        ),
        linear-gradient(90deg, #004DFF, #004DFF) !important;
}